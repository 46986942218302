<template>
  <moe-page title="热门搜索添加">
    <moe-form ref="hotSearchAddForm" :model="hotSearchParams" :rules="rules">
      <el-form-item label="热门搜索名称" prop="name">
        <el-input v-model.trim="hotSearchParams.name" placeholder="请输入热门搜索名称" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="是否展示" prop="status">
        <el-radio-group v-model="hotSearchParams.status">
          <el-radio :label="1">展示</el-radio>
          <el-radio :label="2">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input :value="hotSearchParams.sort" @input="(value) => hotSearchParams.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入活动排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" autosize v-model.trim="hotSearchParams.remark" placeholder="输入备注" maxlength="50" clearable />
      </el-form-item>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="hotSearchAddLoad" @click="submit">
          {{ hotSearchAddLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'HotSearchAdd',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      hotSearchParams: {
        id: '',
        name: '',
        status: 1,
        sort: '',
        remark: '',
      },
      rules: {
        name: verify.isEmpty('请输入热门搜索名称'),
        status: verify.isEmpty('请选择是否展示'),
      },
      hotSearchAddLoad: false,
    }
  },
  methods: {
    submit() {
      this.$refs['hotSearchAddForm'].validate(() => {
        this.hotSearchAddLoad = true;
        if (this.$route.query.id) {
          /** 修改热门搜索 */
          this.$moe_api.GOODS_API.searchUpdate(this.hotSearchParams).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('修改成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.hotSearchAddLoad = false
          })
        } else {
          /** 新增热门搜索 */
          this.$moe_api.GOODS_API.searchAdd(this.hotSearchParams).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.hotSearchAddLoad = false
          })
        }
      })

    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.$route.meta.title = '热门搜索修改'
      /** 热门搜索详情 */
      this.$moe_api.GOODS_API.searchDetails({ id: this.$route.query.id }).then((data) => {
        if (data.code === 200) {
          let { id, name, status, sort, remark } = data.result;

          this.hotSearchParams = {
            id,
            name,
            status,
            sort,
            remark
          };
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    } else {
      this.$route.meta.title = '热门搜索添加'
    }
  }
}
</script>